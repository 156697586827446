import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import IndexCard from "../components/index-card"
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup
} from 'react-bootstrap';

const HireMe = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
    	<h1>Hire Me as a Freelance Software Engineer</h1>

    	<Row>
    		<Col>
    			<p>
    				Do you need a <strong>freelance full stack software engineer in the Bay Area</strong> who is experienced in full stack web development to make or maintain a website for you?  One that has <strong>years of experience</strong> finishing projects on time and who can learn whatever web framework or software stack that's needed to get the job done?  Well you can stop looking because I'm the one for the job!  
    			</p>
    			<p>
    				At 26 years old, <strong>I've been programming for over a decade</strong> in my free time; in school; and professionally.  The better half of this time I've focused on web development.  Starting my web development journey with a simple stack built on PHP, Apache, MySQL, JavaScript, CSS, and Linux (often known as a LAMP stack.)  Over the years since, I've delved into various web development frameworks including <strong>Ruby on Rails; Django and Flask with Python; Express.js; and even Phoenix and Elixir!</strong>
    			</p>
    			<p>
    				With <strong>two degrees</strong>, one in <strong>Mathematics</strong> and the other in <strong>Computer Science</strong>, the fact that I'm an Autodidact and enjoy learning I'm ready to dive into new technology stacks.  I have extended experience in doing so, and even migrating from one new stack to another.  I've migrated projects 3 tiems in my professional life and even have experience fixing bugs in open source software.  <strong>Since the start of 2019, I've created five different web apps using four diffent frameworks and varying technology stacks.</strong>  I have extended experience in backend, frontend, and full stack software development, both recreationally as well as professionally.  I live and breathe this stuff.
    			</p>
    			<p>
    				As far as hosting goes, I've worked with both managed and unmannaged servers and VPS.  I also have a fair bit of knowledge in developing for the cloud as well as serverless configurations.  This includes <strong>Amazon Web Services (AWS), Google Cloud Platform (GCP), Firebase, and Linode</strong> -- just to name a few.
    			</p>
    		</Col>
    	</Row>

    	<b>
	    	<h2>Non-Negotiable Terms of Hiring</h2>
	    	<Row>
	    		<Col>
	    			<p><strong>I am only for hire as a software engineer or related job role where writing software is a major portion of my role.  $250 USD per hour.  40 hour contract minimums.  Payment 24 hours before work begins.  A maximum of 8 hour of work per day.  A maximum of 40 hour of work per week.  Overtime is ($250 USD * (2^x)), paid out daily, where x is the number of hours of overtime and (2^x) is 2 to the power of x (The reason for this is that I like work-life balance and will make it prohibitively expensive to prevent imbalance.)  If payment isn't made on time, then each party is obligated to file suit in the nearest courthouse to Walnut Creek, California in the United States of America in order to notify the court of missing payment.  Upon request, Logan Besecker may take a drug test of his choosing at the expense of one of the other parties involved.</strong></p>
	    		</Col>
	    	</Row>
    	</b>
    </Container>
  </Layout>
)

export default HireMe